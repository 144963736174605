import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import authSlice from "../slice/authSlice";
import loaderReducer from "../slice/loaderSlice";
import toastReducer from "../slice/toasterSlice";
import experReducer from "../slice/expertSlice";
import teamReducer from "../slice/teamSlice";

import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import userReducer from "../slice/userSlice";
import templatesReducer from "../slice/templatesSlice";
import projectReducer from "../slice/projectSlice";
import adminReducer from "../slice/adminSlice";
import leadReducer from "../slice/leadSlice";
import paymentReducer from "../slice/paymentSlice";
import taskChecklistReducer from "../slice/taskChecklistSlice";
import jobsSliceReducer from "../slice/jobsSlice";
import costingReducer from "../slice/costingSlice";
import calendarSliceReducer from "../slice/calendarSlice";
import commonReducer from "../slice/commonSlice";

const persistConfig = {
  key: "root",
  // version: 1,
  storage,
  whitelist: ["auth"],
};

const reducer = combineReducers({
  auth: authSlice,
  loader: loaderReducer,
  expert: experReducer,
  admin: adminReducer,
  common: commonReducer,
  templates: templatesReducer,
  team: teamReducer,
  toaster: toastReducer,
  projects: projectReducer,
  user: userReducer,
  lead: leadReducer,
  payment: paymentReducer,
  taskChecklist: taskChecklistReducer,
  timeline: jobsSliceReducer,
  costing: costingReducer,
  calendar: calendarSliceReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
