/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_ADD_TEAM_MEMBERS, API_GET_TEAM_MEMBERS, BASE_URL } from 'src/constants/apiUrls';
import { apiGetData, apiPostData } from '../../services/api.service';



const initialState = {
    team: [],
    loading: 'idle', // 'idle' | 'pending'
    error: null
};

const token = localStorage.getItem('token');


export const getTeamMembers = createAsyncThunk('@team/getTeamMembers', async (  thunkAPI) => {
    try {
        const response = await apiGetData(`${BASE_URL}${API_GET_TEAM_MEMBERS}`)
        console.log("API_GET_TEAM_MEMBERS",response);

      return response.data.response[0].members 
      
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
    
})

export const addTeamMembers = createAsyncThunk('@team/addTeamMembers', async (payload,thunkAPI) => {
    try {
        const response = await apiPostData(`${BASE_URL}${API_ADD_TEAM_MEMBERS}`,payload)
        console.log("addTeamMembers",response);
      return response.data.response.members
      
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
    
})

const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTeamMembers.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(getTeamMembers.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.team = action.payload
        })
        builder.addCase(getTeamMembers.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload
        })
        builder.addCase(addTeamMembers.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(addTeamMembers.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.team = action.payload
        })
        builder.addCase(addTeamMembers.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload
        })
    }
});

// selectors 

export const selectTeams = (state) => state.team.team;
console.log("selectTeams",selectTeams);

export const selectStatus=state=>state.team.loading
export const selectError=state=>state.team.error


// export const {} = teamSlice.actions

// hi
export default teamSlice.reducer;
