import React from 'react'
import { Pagination, PaginationItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import houseImg from 'src/assets/images/house.svg';
import NoContent from '../NoContent';
import styles from './dataGrid.module.scss';


const DataTableGrid = ({ data = [], columns, currentPage, totalCount = 1, onChange, colorObj, compareKey, listSize = 10, showPagination= true, ...rest }) => {

    const dataGridClasses = {
        columnHeaderTitle: `${styles.table_header}`,
        virtualScrollerContent: `${styles.rowSpacing}`,
        root: `${styles.rootContainer}`,
        columnHeaderTitleContainer: `${styles.table_header}`,
        columnHeader: `${styles.header_outline}`,
        cell: `${styles.cell_container}`,
        columnSeparator: `${styles.columnSeparator}`,
        menuIcon: `${styles.menuIcon}`,
        iconButtonContainer: `${styles.iconHandle}`,
        sortIcon: `${styles.sortIcon}`
    };


      const getPropertyValue = (obj, propertyPath) => {
        const parts = propertyPath?.split('.');
        let result = obj;
        parts?.forEach(part => {
          // eslint-disable-next-line no-prototype-builtins
          if (result.hasOwnProperty(part)) {
            result = result[part];
          } else {
            result = undefined;   
          }
        });
        return result;
      };
      

    const getRowClassName = (params) => {
        const _color = getPropertyValue(params.row, compareKey);
        return `${styles.default} ${compareKey ? (colorObj[_color] ? styles[colorObj[_color]] : styles.other) : ''}`;
    }

    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);
    
    const noRowsOverlay = () => <NoContent src={houseImg} title="No Data Found" isSmall />;
    return (
        <div className={styles.table_wrapper}>
          <DataGrid
            classes={dataGridClasses}
            sx={{ overflowX: 'auto' }}
            rows={data}
            columns={columns}
            hideFooter
            autoHeight
            autoWidth
            getRowSpacing={getRowSpacing}
            getRowClassName={getRowClassName}
            components={{
                NoRowsOverlay: noRowsOverlay
            }}
            {...rest}
          />
          {(showPagination && data?.length > 0) &&
            <div className='float-end m-2 pt-2'>
              <Pagination count={Math.ceil(totalCount / listSize)} onChange={onChange} page={currentPage} shape="rounded" renderItem={(item) => <PaginationItem classes={{ selected: styles.selected, page: styles.page, icon: styles.icon }} {...item} />} />
            </div>}
        </div>
    )
}

export default DataTableGrid