export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_LOGIN = "/CORE/public/admin/login";
export const API_FORGOT_PASSWORD = "/CORE/public/admin/forgotPassword";
export const API_RESET_PASSWORD = "/CORE/public/admin/resetPassword";
export const API_RESET_FIRST_PASSWORD = "/CORE/user/resetFirstPassword";
export const API_USER = "/CORE/user"; // both GET & POST -> append userID
export const API_LEADS = "/CORE/lead";
export const API_GET_LEAD = `${API_LEADS}/user/`;
export const API_COMMON = "/CORE";
export const API_ADMIN_ROUTE = "CORE/admin";
export const API_ADMIN_AGG = "/AGG";
export const API_ADMIN_AUDIT = "/AUDIT";
export const API_NOTIFICATION = "/notifications";
export const API_NOTIFICATION_MARK = "/notification";
export const API_MARK_NOTIFICATION_READ = "/markNotificationAsRead";


// CORE
export const API_GET_TEAM_MEMBERS = "/CORE/user/getTeamMembers";
export const API_ADD_TEAM_MEMBERS = "/CORE/user/addMemberToTeam";
export const API_GET_USER_DETAILS = "/CORE/user/getUserDetailsByEmailOrPhoneNumber";


// Experts
export const API_GET_EXPERTS = "/CORE/user/getExperts";

// file upload
export const API_FILE_UPLOAD = "/CORE/s3/uploadFiles";

//Checklist
export const API_CHECKLIST = "PM/admin/checklist";
export const API_TASK_CHECKLIST = "PM/admin/taskChecklist";

// project
export const API_PROJECT = "/PM/admin/project"; //both GET & POST
export const API_UPDATE_STAGES = `${API_PROJECT}/updateStages`;
export const API_STAGE_USING_TEMPLATE = `${API_PROJECT}/generateStageUsingTemplate`;
export const API_ADD_PAYMENT_PLAN = `${API_PROJECT}/addPaymentPlan`;

export const API_PROJECT_GET_PROJECTS_BY_LOCATION = "/PM/admin/project/getProjectsByLocation/getProjects";


export const API_GET_MATERIALS = `PM/admin/costingTemplate/getMaterialsByLocation`;


//User-management
export const API_USER_MANAGEMENT = "/admin/user";
//User-Management-USER

export const API_GET_USERS = "admin/user/getAllUsers";
export const API_DEACTIVATE_USER = "admin/user/deactivateUser";
export const API_REACTIVATE_USER = "admin/user/reactivateUser";
export const API_USER_ACTIVITY = "/getUserProfileActivity/";

//User-Management-Expert
export const API_GET_ALL_EXPERTS = "admin/expert/getAllExperts";

//User-Management-Expert
// export const API_GET_ALL_ADMINS = 'admin'
// export const API_GET_ALL_LEADS = 'expert/'

//operationDashboard-CreateExpert
export const URL_PROJECT = "/projects";
export const URL_ADD = "/add";
export const API_ADMIN = "admin/expert";
export const API_LEAD = "admin/lead";
export const API_CREATE_EXPERTS = "/createExpert";
export const API_REQUEST_TO_JOIN = "/acceptRequestToJoin";
export const API_GENERATE_EXPERT_CONTRACT = "/generateExpertContract";
export const API_SEND_EXPERT_CONTRACT = "/sendExpertContract";
export const API_GET_EXPERT_BY_STATUS = "/getExpertsByStatus";
export const API_GET_ADMIN_EXPERT = "/getExperts";
export const API_GET_ALL_ADMINS = "admin/";
export const API_GET_EXPERT_REQUESTS = "/getExpertRequests";
export const API_PROJECTS = "/PM/admin/project";
export const API_PROJECTS_FOR_PAYMENT = "/PM/project";

export const API_ONGOING_PROJECTS = `${API_PROJECTS}/getOngoingProjects`;
export const URL_PROJECT_ADD = `${URL_PROJECT}${URL_ADD}`;
export const API_LOG = "/log/operationDashboardInfo";
export const API_EXPERT_DATA = "/getExpertProfileForOperationDashboard";
export const API_ADD_FEATURED = "/expert/addExpertToFeatured";
export const API_REMOVE_FEATURED = "/expert/removeExpertFromFeatured";

//content-reviewer
export const API_URL_PORTFOLIO_DETAILS = "CORE/admin/portfolio/";
export const API_URL_BLOG_DETAILS = "CORE/admin/blog/";
export const API_DELETE_COMMENT = "admin/blog/deleteComment/";
export const API_COMMENT_BLOG = `${API_URL_BLOG_DETAILS}/addComment`;
export const API_COMMENT_PORTFOLIO = `${API_URL_PORTFOLIO_DETAILS}/addComment`;
export const API_BLOG = "/blog";
export const API_PORTFOLIO = "/portfolio";

// blogs
export const API_GET_ALL_BLOGS = "/admin/blog";
export const API_GET_ALL_PORTFOLIOS = "/admin/portfolio";

// task
export const API_TASK = "PM/admin/task";
export const API_ADD_ACTUAL_COST = `${API_TASK}/addActualCost`;

//payment-plan
export const API_PAYMENT_PLAN = "/PM/admin/payment";
export const GET_PAYMENT_SCHEDULE = `${API_PAYMENT_PLAN}/getAllPaymentSchedule`;
export const GET_PROJECT_PAYMENT_SCHEDULE = `${API_PAYMENT_PLAN}/getPaymentScheduleByProject`;
export const RECEIVE_PAYMENT = `${API_PAYMENT_PLAN}/receivePayment`;
export const SPLIT_PAYMENT = `${API_PAYMENT_PLAN}/splitPayment`;
export const API_VERIFY_PAYMENT = `${API_PAYMENT_PLAN}/verifyPayment`;
export const API_STATEMENTS = `${API_PAYMENT_PLAN}/getProjectStatement`;
export const API_RAZORPAY_LINK = `${API_PAYMENT_PLAN}/createRazorpayPaymentLink`;


//sales-dashboard
export const API_URL_SALES_LEAD = "/lead/getSalesDashboard";
export const API_URL_LEAD_DETAILS = "/lead/";
export const API_GET_LEADS_BY_STATUS = `${API_ADMIN_ROUTE}/lead/getLeadsByStatus`;

export const API_PROJECT_TIMELINE = `${API_PROJECTS}/timeline`;
export const API_PROJECT_UPDATE_TIMELINE = `${API_PROJECTS}/updateTimeline`;

export const API_URL_LEAD_ADD = `${API_URL_LEAD_DETAILS}createLead`;
export const API_GENERATE_PROJECT_QUOTATION = `admin/pmc/generateProjectQuotation`;
export const API_SEND_PROJECT_QUOTATION = `admin/pmc/sendProjectQuotation`;

//Project Documents
export const API_PROJECT_DIRECTORY = "/PM/project/directory";
export const API_ADD_EXTERNAL_LINK = `${API_PROJECT_DIRECTORY}/addExternalFile`;
export const API_SKILLS = "/CORE/skills";
// tags
export const API_TAGS = "/CORE/tag";

//user-activity

export const API_USER_ACTIVITY_STAT = "/log/getUserActivityDashboardStats";


//Templates
export const API_TEMPLATE = "PM/admin/template"; //both GET & POST
export const API_COSTING_TEMPLATE = "PM/admin/costingTemplate";
export const API_LATEST_TEMPLATES = `${API_COSTING_TEMPLATE}/getLatestCostingTemplates`;
export const API_TEMPLATES_BY_LOCATION = `${API_COSTING_TEMPLATE}/getCostingTemplatesByLocation`;

// costing
export const API_GET_PROJECT_COSTING = `${API_PROJECTS}/getProjectCosting`;
export const API_UPDATE_COSTING_ONGOING = `${API_PROJECTS}/updateCostingTemplateForOngoingTasks`;
export const API_UPDATE_COSTING_UPCOMING = `${API_PROJECTS}/updateCostingTemplateForUpcomingTasks`;
export const API_UPDATE_COSTING_COMPLETED = `${API_PROJECTS}/updateActualsForCompletedTasks`;

// Calendar
export const API_MEETING = "/CORE/meeting";
export const API_GET_CALENDAR = `${API_MEETING}/calendar`;
